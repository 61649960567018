<template>
    <main-page>
        <b-container class="text-center">
            <lottery-wheel v-if="participants" ref="lottery" v-model="winner" :prizes="participants.members" @won="won"></lottery-wheel>

            <b-btn @click="$refs.lottery.startSpinning()">Faire tourner la roue</b-btn>
        </b-container>
    </main-page>
</template>

<script>
import Participants from "@/helpers/Participants.js";
import MainPage from "@/components/main-page";
import LotteryWheel from "@/components/lottery-wheel";

export default {
    name: "lottery",
    components: {LotteryWheel, MainPage},
    data() {
        return {
            participants: null,
            winner: null
        }
    },
    mounted() {
        this.participants = new Participants();
    },
    methods: {
        won(participant) {
            console.log(`Yeah`, participant);
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
