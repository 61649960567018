<template>
    <div>
        <div class="wheel mx-auto" :style="{ width: diameter, height: diameter }">
            <div class="wheel-container">
                <b-img class="background" :src="require('../assets/img/roulette-background.svg')"></b-img>
<!--                <div class="wheel-start-container">-->
<!--                    <div class="wheel-arrow"></div>-->
<!--                </div>-->
                <div class="wheel-background" :style="wheelRotation">
                    <b-img class="wheel" :src="require('../assets/img/roulette-wheel.svg')"></b-img>
                    <div class="prize-list split">
                        <div class="prize-list prize-item-container" v-for="(prize, index) in prizes" :key="index"
                             :style="sliceRotation(index)">
                            <div class="prize-list prize-item" :style="slice">
                                <div class="prize-name word-art">
                                    {{ prize.name }}
                                </div>
<!--                                <div v-if="prize.image">-->
<!--                                    <img style="max-width: 50%; max-height: 50%;" :src="prize.image"/>-->
<!--                                </div>-->
                            </div>
                        </div>
                    </div>
<!--                    <div class="prize-list">-->
<!--                        <div class="prize-list prize-item-container" v-for="(prize, index) in prizes" :key="index"-->
<!--                             :style="sliceRotation(index)">-->
<!--                            -->
<!--                        </div>-->
<!--                    </div>-->
                </div>
                <b-img class="arrow" :src="require('../assets/img/roulette-marker.svg')"></b-img>
            </div>
<!--            <div class="winner-container word-art" style="position: absolute; font-size: 40px;">-->
<!--                {{currentAngle}}deg-->

<!--                [{{currentIndex}}]-->

<!--                {{currentWinner}}-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
import confetti from "canvas-confetti";

export default {
    name: "lottery-wheel",
    props: {
        value: {
            type: Object
        },
        prizes: {
            type: Array
        },
        diameter: {
            default: "700px",
            type: String
        }
    },
    data() {
        return {
            audio: {
                spin: new Audio('https://freesound.org/data/previews/120/120373_824230-lq.mp3'),
                spinEnd: new Audio('https://freesound.org/data/previews/145/145441_2615119-lq.mp3'),
                lock: new Audio('https://freesound.org/data/previews/56/56246_91374-lq.mp3')
            },
            currentAngle: 0,
            lastWinnerIndex: 0,
            playing: false,
            showConfettis: false
        };
    },
    computed: {
        sliceAngle() {
            return this.prizes.length > 0 ? 360 / this.prizes.length : 0;
        },
        sliceOffset() {
            return this.sliceAngle / 2;
        },
        currentIndex() {
            const index = Math.round(((this.currentAngle - this.sliceOffset) % 360) / this.sliceAngle) % this.prizes.length;
            return index;
        },
        currentWinner() {
            return this.prizes[this.currentIndex].name;
        },
        wheelRotation() {
            return {
                transform: `rotate(${this.currentAngle}deg)`
            };
        },
        slice() {
            return {
                width: `calc(100% * ${Math.PI} / ${this.prizes.length})`
            };
        }
    },
    mounted() {

        this.audio.spin.volume = 0.3
        this.audio.spin.loop = true;
        this.audio.spinEnd.volume = 0.8
        this.audio.lock.volume = 0.2
        this.audio.spin.currentTime = 0.3
    },
    methods: {
        startSpinning() {
            if (this.playing)
                return;

            this.playing = true;
            this.$emit(`start-spinning`);
            this.speed = 30 + Math.random() * 30;
            this.audio.spin.play();

            this.currentAngle = -22.5;
            this.updateSpin();
        },
        updateSpin() {
            this.speed *= 0.985;

            this.currentAngle = (this.currentAngle + this.speed) % 360;
            
            if (this.speed > 0.1) {
                window.requestAnimationFrame(this.updateSpin);
            } else {
                this.stopSpinning();
            }
        },
        stopSpinning() {
            confetti({
                particleCount: 150,
                angle: 60,
                spread: 55,
                origin: { x: 0, y: 1 }
            });
            // and launch a few from the right edge
            confetti({
                particleCount: 150,
                angle: 120,
                spread: 55,
                origin: { x: 1, y: 1 }
            });
            this.showFireworks();

            this.audio.spin.pause();
            this.audio.spinEnd.play();

            setTimeout(() => {
                confetti.reset();
            }, 10000);
            this.playing = false;
            // this.$emit(`input`, this.prizes[winner]);
            // this.$emit(`won`, this.prizes[winner]);
        },
        sliceRotation(index) {
            const currentAngle = index * this.sliceAngle + this.sliceOffset;
            return {transform: `rotate(${currentAngle}deg)`};
        },
        showFireworks() {
            let duration = 5 * 1000;
            let animationEnd = Date.now() + duration;
            let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min;
            }

            let interval = setInterval(function() {
                let timeLeft = animationEnd - Date.now();

                if (timeLeft <= 0) {
                    return clearInterval(interval);
                }

                let particleCount = 50 * (timeLeft / duration);
                // since particles fall down, start a bit higher than random
                confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } }));
                confetti(Object.assign({}, defaults, { particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } }));
            }, 250);
        }
    }
};
</script>

<style lang="scss" scoped>
canvas {
    z-index: 999 !important;
}

.wheel {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 10px;
}

.wheel-container {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img.background {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    img.wheel {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    img.arrow {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.wheel-start-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 20%;
    height: 20%;
    z-index: 2;
}

.wheel-background {
    width: inherit;
    height: inherit;
    //background-color: #ddd;
    //background: conic-gradient(from 22.5deg, red, orange);
    //background: radial-gradient(red, orange);
    border-radius: 50%;
    //border: 1px solid black;
    //filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
    box-sizing: border-box;
    position: absolute;
}

.wheel-arrow {
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-bottom: 300px solid #000;
    position: absolute;
    transform: scaleX(0.6) translateY(-240px);
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.6));
}

.wheel-start-text {
    position: absolute;
    width: 100%;
    top: 30%;
    font-size: 2em;
    z-index: 2;
}

.prize-list {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
}

.prize-item-container {
    display: flex;
    justify-content: center;
}

.prize-name {
    text-align: center;
    font-size: 40px;
    color: #fff;
    transform: translate(-0px, 92px) rotate(-90deg);
}

.word-art {
    letter-spacing: -0.01em;
    font-family: Impact;
    background: linear-gradient(to bottom, #ffffff 0%, #d6cbd9 73%);
    //-webkit-text-stroke: 0.01em #7e7099;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.prize-item {
    height: 50%;
    margin: auto;
    position: absolute;
}

.confettis {
    position: fixed;
    pointer-events: none;
    height: 100%;
    width: 100%;
}

//.split > .prize-item-container:before {
//    position: absolute;
//    content: "";
//    height: 50%;
//    border-right: 2px solid black;
//    -webkit-font-smoothing: none;
//}
</style>