<template>
  <div class="main-page">
      <b-navbar class="main-menu" variant="dark" fixed="top">
          <b-navbar-brand to="/">
              <b-img height="32" :src="require('../assets/logo.png')"></b-img>
          </b-navbar-brand>

          <b-nav>
              <b-nav-item to="/">Accueil</b-nav-item>
          </b-nav>
          <b-nav>
              <b-nav-item to="/lottery">Lotterie</b-nav-item>
          </b-nav>
          <b-nav>
              <b-nav-item to="/slot-machine">Bandit Manchot</b-nav-item>
          </b-nav>
          <b-nav>
              <b-nav-item to="/randomator">Randomator</b-nav-item>
          </b-nav>
      </b-navbar>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'main-page'
}
</script>

<style lang="scss" scoped>
.navbar.main-menu {
    background: linear-gradient(to bottom, #111, #000);

    ::v-deep {
        .nav-item {
            a {
                color: #ccc;
                border-bottom: 2px solid transparent;

                &.router-link-exact-active {
                    color: white;
                    border-bottom: 2px solid white;
                }
            }
        }

    }
}

.main-page {
    padding-top: 60px;
    width: 100vw;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden auto;
}
</style>
