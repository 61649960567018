export default class Participants {
    constructor() {
        this.members = [];
        this.addMember(`Sophie`, `Boudreau`);
        this.addMember(``, ``);
        this.addMember(`Eric`, `Ethier`);
        this.addMember(`Arianne`, `Ethier`);
        this.addMember(`Guillaume`, `Roux`);
        this.addMember(``, ``);
        this.addMember(`Théo`, `Ethier`);
        this.addMember(`Eden`, `Ethier`);
    }

    addMember(firstName, lastName) {
        this.members.push({
            name: firstName,
            firstName,
            lastName
        });
    }
}
